<script setup>
const nuxtApp = useNuxtApp()

const { data, status, error } = await useLazyAsyncData('featureddeals', () => $fetch('/api/deal/featured'), {
    transform: (data) => {
        return {
            featuredDeals: data.deals.edges.map((edge) => edge.node),
            fetchedAt: new Date(Date.now()),
        }
    },
    getCachedData(key) {
        const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]

        if (!data) {
            return
        }

        const expirationDate = new Date(data.fetchedAt)
        expirationDate.setTime(expirationDate.getTime() + 60 * 60000) // cache set to 1 hour
        const isExpired = expirationDate.getTime() < Date.now()
        if (isExpired) {
            return
        }

        return data
    },
})

if (error.value) {
    throw createError({
        statusCode: 500,
        message: error.value,
        fatal: false,
    })
}
</script>

<template>
    <v-row id="featureddeals" class="pb-16 justify-center bg-grey-lighten-3 rounded">
        <v-col v-if="status === 'pending' || status === 'idle'" cols="10"><app-loader /></v-col>
        <v-col v-else cols="10" sm="10">
            <v-row>
                <v-col cols="12" class="mt-16 mb-4">
                    <app-section-header
                        :id="`featureddeals`"
                        :title="`Curated Deals`"
                        :subtitle="`Amazing deals that you can't miss`"
                    />
                </v-col>
            </v-row>
            <v-row class="justify-center">
                <v-col v-for="deal in data.featuredDeals" :key="deal.id" cols="12" sm="6" lg="3">
                    <deal-card :deal="deal" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="mb-16 mt-10 d-flex justify-center">
                    <v-theme-provider theme="sTLightTheme">
                        <app-button
                            :to="`/deals`"
                            :btn-text="`View All Deals`"
                            :append-icon="`mdi-chevron-right`"
                            size="x-large"
                            aria-label="View all Deals"
                            :btn-color="`primary`"
                        />
                    </v-theme-provider>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped></style>
